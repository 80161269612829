import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Editor } from "@tinymce/tinymce-react";
import logo from "./assets/isgreatlogo.png";
import "./App.css";
import "./index.css";

export default function App() {
  type PageAuthor = {
    firstName: string;
    email: string;
  };

  const stageDefault: string = "prod";
  const pageContentDefault: string = "<p>Loading ...</p>";
  const currentPageDefault: string = "home";
  const pageNameDefault: string = "Home Page";
  const pageTitleDefault: string = "is Great Blog";
  const pageTypeDefault: string = "content";
  const pageUrlDefault: string = "https://www.isgreat.blog";
  const apiEndPointDefault: string = "https://api.isgreat.blog/api/prod";
  const userPoolIdDefault: string = "us-east-1_1kjCvGHke";
  const pageAuthorDefault: PageAuthor = {
    firstName: "Support",
    email: "support@growthexponent.com",
  };
  const apiKeyDefault: string = "FnXKeebvYX5VON3xATtV08cUeOqKO5tD7qnYr94e";
  const getContentUrlDefault: string =
    "https://7m4vafbe2vwixgbgvdkeebtupy0eelew.lambda-url.us-east-1.on.aws";

  const [stage, setStage] = useState(stageDefault);
  const [pageContent, setPageContent] = useState(pageContentDefault);
  const [currentPage, setCurrentPage] = useState(currentPageDefault);
  const [pageName, setPageName] = useState(pageNameDefault);
  const [pageTitle, setPageTitle] = useState(pageTitleDefault);
  const [pageType, setPageType] = useState(pageTypeDefault);
  const [pageUrl, setPageUrl] = useState(pageUrlDefault);
  const [pageAuthor, setPageAuthor] = useState(pageAuthorDefault);
  const [apiEndPoint, setApiEndPoint] = useState(apiEndPointDefault);
  const [userPoolId, setUserPoolId] = useState(userPoolIdDefault);
  const [apiKey, setApiKey] = useState(apiKeyDefault);
  const [getContentUrl, setGetContentUrl] = useState(getContentUrlDefault);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getContent();
  }, [getContentUrl]);

  const getConfig = () => {
    let configFile: {
      stage: string;
      userPoolId: string;
      apiEndPoint: string;
      apiKey: string;
      getContentUrl: string;
    } = {
      stage: stage,
      userPoolId: userPoolId,
      apiEndPoint: apiEndPoint,
      apiKey: apiKey,
      getContentUrl: getContentUrl,
    };

    fetch("config.json")
      .then((response) => response.text())
      .then((text) => {
        if (stage != "prod") console.log(text);
        configFile = JSON.parse(text);
        if (stage != "prod") console.log(configFile);
        setStage(configFile.stage);
        setUserPoolId(configFile.userPoolId);
        setApiEndPoint(configFile.apiEndPoint);
        setApiKey(configFile.apiKey);
        setGetContentUrl(configFile.getContentUrl);
      });
    getContent();
  };

  const getContent = () => {
    let pageData: {
      pageContent: string;
    } = {
      pageContent: pageContent,
    };

    if (stage != "prod") console.log("getContentUrl: " + getContentUrl);
    fetch(getContentUrl + "/" + currentPage)
      .then((response) => response.text())
      .then((text) => {
        if (stage != "prod") console.log(text);
        if (text.length > 0) {
          pageData = JSON.parse(text);
          if (stage != "prod") console.log(pageData.pageContent);
          setPageContent(pageData.pageContent);
        }
      });
  };

  const onSaveText = () => alert("Save!");

  return (
    <>
      <PageHeader pageTitle={pageTitle} />
      <PageContent pageContent={pageContent} />
      <TinyEditor
        stage={stage}
        currentPage={currentPage}
        pageName={pageName}
        pageTitle={pageTitle}
        pageType={pageType}
        pageUrl={pageUrl}
        pageContent={pageContent}
        pageAuthor={pageAuthor}
        apiEndPoint={apiEndPoint}
        apiKey={apiKey}
      />
    </>
  );
}

function PageHeader({ pageTitle }: { pageTitle: string }) {
  return (
    <div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="font-sans text-4xl font-extrabold">{pageTitle}</h1>
      </header>
    </div>
  );
}

function PageContent({ pageContent }: { pageContent: string }) {
  return (
    <div className="font-mono font-medium text-white">{parse(pageContent)}</div>
  );
}

function TinyEditor({
  stage,
  currentPage,
  pageName,
  pageTitle,
  pageType,
  pageUrl,
  pageContent,
  pageAuthor,
  apiEndPoint,
  apiKey,
}: {
  stage: string;
  currentPage: string;
  pageName: string;
  pageTitle: string;
  pageType: string;
  pageUrl: string;
  pageContent: string;
  pageAuthor: any;
  apiEndPoint: string;
  apiKey: string;
}) {
  type PageAuthor = {
    firstName: string;
    email: string;
  };

  type IGBPageData = {
    /**
     * The Environment Stage: dev or prod
     */
    stage: string;
    /**
     * The Page ID
     */
    pageId: string;
    /**
     * The Page Name
     */
    pageName: string;
    /**
     * The Page Title
     */
    pageTitle: string;
    /**
     * The Page Type
     */
    pageType: string;
    /**
     * The Page URL
     */
    pageUrl: string;
    /**
     * The Page content
     */
    pageContent: string;
    /**
     * The Page Author
     */
    pageAuthor: PageAuthor;
    /**
     * API Endpoint to send requests
     */
    apiEndPoint: string;
    /**
     * API Key
     */
    apiKey: string;
  };

  const textDefault: string = "";
  const initialPageData: IGBPageData = {
    stage: stage,
    pageId: currentPage,
    pageName: pageName,
    pageTitle: pageTitle,
    pageType: pageType,
    pageUrl: pageUrl,
    pageContent: pageContent,
    pageAuthor: pageAuthor,
    apiEndPoint: apiEndPoint,
    apiKey: apiKey,
  };

  const [env, setEnv] = useState(stage);
  const [value, setValue] = useState(pageContent);
  const [text, setText] = useState(textDefault);
  const [pageData, setPageData] = useState(initialPageData);
  const [saveApiEndPoint, setApiEndPoint] = useState(apiEndPoint);
  const [saveApiKey, setApiKey] = useState(apiKey);

  useEffect(() => {
    // Do something
  }, []);

  useEffect(() => {
    setEnv(stage);
    const tmpPageData: IGBPageData = pageData;
    if (stage != "prod") {
      tmpPageData.pageUrl = "https://" + stage + ".isgreat.blog";
      setPageData(tmpPageData);
    }
  }, [stage]);

  useEffect(() => {
    setApiEndPoint(apiEndPoint);
    const tmpPageData: IGBPageData = pageData;
    tmpPageData.apiEndPoint = apiEndPoint;
    setPageData(tmpPageData);
  }, [apiEndPoint]);

  useEffect(() => {
    setApiKey(apiKey);
    const tmpPageData: IGBPageData = pageData;
    tmpPageData.apiKey = apiKey;
    setPageData(tmpPageData);
  }, [apiKey]);

  useEffect(() => {
    setValue(pageContent);
  }, [pageContent]);

  function handleSubmit(e: { preventDefault: () => void }) {
    e.preventDefault();
    if (env != "prod") console.log("apiEndPoint: " + apiEndPoint);
    pageData.pageContent = value;
    savePage(saveApiEndPoint + "/content", JSON.stringify(pageData));
  }

  function savePage(url: string, pageData: string) {
    const options = {
      headers: {
        "X-API-Key": saveApiKey,
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: pageData,
    };
    fetch(url, options)
      .then((response) =>
        response.ok ? response.json() : Promise.reject("HTTP error!"),
      )
      .then((data) => {
        if (env != "prod") console.log("Response:", data);
        alert("Content Saved");
      })
      .catch((error) => {
        if (env != "prod") console.error("Error:", error);
      });
  }

  const tinyInit = {
    plugins:
      "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
    toolbar:
      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
    tinycomments_mode: "embedded",
    tinycomments_author: pageAuthor.firstName,
    mergetags_list: [
      { title: "First Name", value: pageAuthor.firstName },
      { title: "Email", value: pageAuthor.email },
    ],
  };
  return (
    <form onSubmit={handleSubmit}>
      <Editor
        value={value}
        onInit={(evt, editor) => {
          setText(editor.getContent({ format: "text" }));
        }}
        apiKey="bjejbhybhyn1h8a657pojfz2zey29889av3wx2w9gvhewr98"
        init={tinyInit}
        // initialValue={initialText}
        onEditorChange={(newValue, editor) => {
          setValue(newValue);
          setText(editor.getContent({ format: "text" }));
        }}
        scriptLoading={{ async: true }}
      />
      <button
        type="submit"
        className="rounded-md bg-white px-2 text-sm font-bold text-blue-500 hover:bg-blue-500 hover:text-white"
      >
        Save
      </button>
    </form>
  );
}
